import Card from "@mui/material/Card";
import {CardActionArea, Grid} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ColivingCard from "./ColivingCard";
import {data} from "../Data";


export default function ListCards() {

    var cards = data.map((colivingData) =>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={colivingData.id} >
            <ColivingCard  colivingData={colivingData} />
        </Grid>
    )
    return(
        <Grid
            container
            justifyContent="center"
            alignItems="center">
            {cards}
        </Grid>
    )
}