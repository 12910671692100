import React, {useState} from 'react';
import './App.css';
import 'react-photo-view/dist/react-photo-view.css';
import TableList from "./components/TableList";
import SearchField from "./components/SearchField";
import PiwikPro from '@piwikpro/react-piwik-pro';
import {Fab, Paper, Typography} from "@mui/material";
import ColivingCard from "./components/ColivingCard";
import ListCards from "./components/ListCards";
import Box from "@mui/material/Box";
import {EmailOutlined} from "@mui/icons-material";

import backgroundImage from './background.jpg'; // Import using relative path

PiwikPro.initialize('e9e04292-0b67-4c1b-9573-96d173cf4d68', 'https://easycoliving.containers.piwik.pro');

const styles = {
    paperContainer: {
        backgroundImage: `url(${backgroundImage})`,
        width: '100%'
    },
};
function DataTable() {
    return (
    <Box style={{ height: 800, width: '100%' }}>
        <Paper style={styles.paperContainer}>
            <Typography sx={{p: 20}} variant="h1" align={'center'} >
                Easy Coliving
            </Typography>
        </Paper>


        {/*<SearchField />*/}
        {/*<div style={{ height: 400, width: '100%' }}>*/}
        {/*    <TableList />*/}
        {/*</div>*/}
        <ListCards />

        <Fab variant="extended" color="primary" aria-label="add"
             data-tally-open="mOQYqp" data-tally-overlay="1" data-tally-emoji-text="👋"
             data-tally-emoji-animation="wave" data-tally-auto-close="-1000"
        >
            <EmailOutlined sx={{ mr: 1 }} />
            Contact Me
        </Fab>
    </Box>
  );
}



function App() {

  return (
      <DataTable />
  );
}

export default App;
